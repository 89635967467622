import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _890265fa = () => interopDefault(import('../pages/accept-invitation.vue' /* webpackChunkName: "pages/accept-invitation" */))
const _37b31ddd = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _110b4019 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _3c46be84 = () => interopDefault(import('../pages/days-off.vue' /* webpackChunkName: "pages/days-off" */))
const _bc2bfaca = () => interopDefault(import('../pages/days-off-calculator.vue' /* webpackChunkName: "pages/days-off-calculator" */))
const _50d3add7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3bd4414a = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _03606768 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _1a7b50ee = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _fc005556 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _04a3b162 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _a767359e = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _9e6d943a = () => interopDefault(import('../pages/company/administrators.vue' /* webpackChunkName: "pages/company/administrators" */))
const _a0d76d10 = () => interopDefault(import('../pages/company/employees.vue' /* webpackChunkName: "pages/company/employees" */))
const _cd70bf5e = () => interopDefault(import('../pages/company/export.vue' /* webpackChunkName: "pages/company/export" */))
const _4b67531e = () => interopDefault(import('../pages/company/groups.vue' /* webpackChunkName: "pages/company/groups" */))
const _beca0160 = () => interopDefault(import('../pages/company/non-working-days.vue' /* webpackChunkName: "pages/company/non-working-days" */))
const _1076e800 = () => interopDefault(import('../pages/company/settings.vue' /* webpackChunkName: "pages/company/settings" */))
const _c949d4c8 = () => interopDefault(import('../pages/register/company.vue' /* webpackChunkName: "pages/register/company" */))
const _05484e90 = () => interopDefault(import('../pages/register/personal-information.vue' /* webpackChunkName: "pages/register/personal-information" */))
const _173446ce = () => interopDefault(import('../pages/register/team.vue' /* webpackChunkName: "pages/register/team" */))
const _6d2ff3e8 = () => interopDefault(import('../pages/user/account-settings.vue' /* webpackChunkName: "pages/user/account-settings" */))
const _33c03dd5 = () => interopDefault(import('../pages/user/approvers.vue' /* webpackChunkName: "pages/user/approvers" */))
const _66b3197c = () => interopDefault(import('../pages/user/days-off.vue' /* webpackChunkName: "pages/user/days-off" */))
const _1dd37abd = () => interopDefault(import('../pages/user/export.vue' /* webpackChunkName: "pages/user/export" */))
const _4c8e6ce8 = () => interopDefault(import('../pages/user/personal-information.vue' /* webpackChunkName: "pages/user/personal-information" */))
const _13870681 = () => interopDefault(import('../pages/user/_id.vue' /* webpackChunkName: "pages/user/_id" */))
const _2445dec1 = () => interopDefault(import('../pages/user/_id/account-settings.vue' /* webpackChunkName: "pages/user/_id/account-settings" */))
const _26a396c0 = () => interopDefault(import('../pages/user/_id/approvers.vue' /* webpackChunkName: "pages/user/_id/approvers" */))
const _1eced0f7 = () => interopDefault(import('../pages/user/_id/days-off.vue' /* webpackChunkName: "pages/user/_id/days-off" */))
const _1e71c1b2 = () => interopDefault(import('../pages/user/_id/export.vue' /* webpackChunkName: "pages/user/_id/export" */))
const _429239c6 = () => interopDefault(import('../pages/user/_id/personal-information.vue' /* webpackChunkName: "pages/user/_id/personal-information" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accept-invitation",
    component: _890265fa,
    name: "accept-invitation___hu___default"
  }, {
    path: "/change-password",
    component: _37b31ddd,
    name: "change-password___hu___default"
  }, {
    path: "/dashboard",
    component: _110b4019,
    name: "dashboard___hu___default"
  }, {
    path: "/days-off",
    component: _3c46be84,
    name: "days-off___hu___default"
  }, {
    path: "/days-off-calculator",
    component: _bc2bfaca,
    name: "days-off-calculator___hu___default"
  }, {
    path: "/en",
    component: _50d3add7,
    name: "index___en"
  }, {
    path: "/forgot-password",
    component: _3bd4414a,
    name: "forgot-password___hu___default"
  }, {
    path: "/help",
    component: _03606768,
    name: "help___hu___default"
  }, {
    path: "/hu",
    component: _50d3add7,
    name: "index___hu"
  }, {
    path: "/login",
    component: _1a7b50ee,
    name: "login___hu___default"
  }, {
    path: "/logout",
    component: _fc005556,
    name: "logout___hu___default"
  }, {
    path: "/privacy-policy",
    component: _04a3b162,
    name: "privacy-policy___hu___default"
  }, {
    path: "/register",
    component: _a767359e,
    name: "register___hu___default"
  }, {
    path: "/company/administrators",
    component: _9e6d943a,
    name: "company-administrators___hu___default"
  }, {
    path: "/company/employees",
    component: _a0d76d10,
    name: "company-employees___hu___default"
  }, {
    path: "/company/export",
    component: _cd70bf5e,
    name: "company-export___hu___default"
  }, {
    path: "/company/groups",
    component: _4b67531e,
    name: "company-groups___hu___default"
  }, {
    path: "/company/non-working-days",
    component: _beca0160,
    name: "company-non-working-days___hu___default"
  }, {
    path: "/company/settings",
    component: _1076e800,
    name: "company-settings___hu___default"
  }, {
    path: "/en/accept-invitation",
    component: _890265fa,
    name: "accept-invitation___en"
  }, {
    path: "/en/change-password",
    component: _37b31ddd,
    name: "change-password___en"
  }, {
    path: "/en/dashboard",
    component: _110b4019,
    name: "dashboard___en"
  }, {
    path: "/en/days-off",
    component: _3c46be84,
    name: "days-off___en"
  }, {
    path: "/en/days-off-calculator",
    component: _bc2bfaca,
    name: "days-off-calculator___en"
  }, {
    path: "/en/forgot-password",
    component: _3bd4414a,
    name: "forgot-password___en"
  }, {
    path: "/en/help",
    component: _03606768,
    name: "help___en"
  }, {
    path: "/en/login",
    component: _1a7b50ee,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _fc005556,
    name: "logout___en"
  }, {
    path: "/en/privacy-policy",
    component: _04a3b162,
    name: "privacy-policy___en"
  }, {
    path: "/en/register",
    component: _a767359e,
    name: "register___en"
  }, {
    path: "/hu/accept-invitation",
    component: _890265fa,
    name: "accept-invitation___hu"
  }, {
    path: "/hu/change-password",
    component: _37b31ddd,
    name: "change-password___hu"
  }, {
    path: "/hu/dashboard",
    component: _110b4019,
    name: "dashboard___hu"
  }, {
    path: "/hu/days-off",
    component: _3c46be84,
    name: "days-off___hu"
  }, {
    path: "/hu/days-off-calculator",
    component: _bc2bfaca,
    name: "days-off-calculator___hu"
  }, {
    path: "/hu/forgot-password",
    component: _3bd4414a,
    name: "forgot-password___hu"
  }, {
    path: "/hu/help",
    component: _03606768,
    name: "help___hu"
  }, {
    path: "/hu/login",
    component: _1a7b50ee,
    name: "login___hu"
  }, {
    path: "/hu/logout",
    component: _fc005556,
    name: "logout___hu"
  }, {
    path: "/hu/privacy-policy",
    component: _04a3b162,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/register",
    component: _a767359e,
    name: "register___hu"
  }, {
    path: "/register/company",
    component: _c949d4c8,
    name: "register-company___hu___default"
  }, {
    path: "/register/personal-information",
    component: _05484e90,
    name: "register-personal-information___hu___default"
  }, {
    path: "/register/team",
    component: _173446ce,
    name: "register-team___hu___default"
  }, {
    path: "/user/account-settings",
    component: _6d2ff3e8,
    name: "user-account-settings___hu___default"
  }, {
    path: "/user/approvers",
    component: _33c03dd5,
    name: "user-approvers___hu___default"
  }, {
    path: "/user/days-off",
    component: _66b3197c,
    name: "user-days-off___hu___default"
  }, {
    path: "/user/export",
    component: _1dd37abd,
    name: "user-export___hu___default"
  }, {
    path: "/user/personal-information",
    component: _4c8e6ce8,
    name: "user-personal-information___hu___default"
  }, {
    path: "/en/company/administrators",
    component: _9e6d943a,
    name: "company-administrators___en"
  }, {
    path: "/en/company/employees",
    component: _a0d76d10,
    name: "company-employees___en"
  }, {
    path: "/en/company/export",
    component: _cd70bf5e,
    name: "company-export___en"
  }, {
    path: "/en/company/groups",
    component: _4b67531e,
    name: "company-groups___en"
  }, {
    path: "/en/company/non-working-days",
    component: _beca0160,
    name: "company-non-working-days___en"
  }, {
    path: "/en/company/settings",
    component: _1076e800,
    name: "company-settings___en"
  }, {
    path: "/en/register/company",
    component: _c949d4c8,
    name: "register-company___en"
  }, {
    path: "/en/register/personal-information",
    component: _05484e90,
    name: "register-personal-information___en"
  }, {
    path: "/en/register/team",
    component: _173446ce,
    name: "register-team___en"
  }, {
    path: "/en/user/account-settings",
    component: _6d2ff3e8,
    name: "user-account-settings___en"
  }, {
    path: "/en/user/approvers",
    component: _33c03dd5,
    name: "user-approvers___en"
  }, {
    path: "/en/user/days-off",
    component: _66b3197c,
    name: "user-days-off___en"
  }, {
    path: "/en/user/export",
    component: _1dd37abd,
    name: "user-export___en"
  }, {
    path: "/en/user/personal-information",
    component: _4c8e6ce8,
    name: "user-personal-information___en"
  }, {
    path: "/hu/company/administrators",
    component: _9e6d943a,
    name: "company-administrators___hu"
  }, {
    path: "/hu/company/employees",
    component: _a0d76d10,
    name: "company-employees___hu"
  }, {
    path: "/hu/company/export",
    component: _cd70bf5e,
    name: "company-export___hu"
  }, {
    path: "/hu/company/groups",
    component: _4b67531e,
    name: "company-groups___hu"
  }, {
    path: "/hu/company/non-working-days",
    component: _beca0160,
    name: "company-non-working-days___hu"
  }, {
    path: "/hu/company/settings",
    component: _1076e800,
    name: "company-settings___hu"
  }, {
    path: "/hu/register/company",
    component: _c949d4c8,
    name: "register-company___hu"
  }, {
    path: "/hu/register/personal-information",
    component: _05484e90,
    name: "register-personal-information___hu"
  }, {
    path: "/hu/register/team",
    component: _173446ce,
    name: "register-team___hu"
  }, {
    path: "/hu/user/account-settings",
    component: _6d2ff3e8,
    name: "user-account-settings___hu"
  }, {
    path: "/hu/user/approvers",
    component: _33c03dd5,
    name: "user-approvers___hu"
  }, {
    path: "/hu/user/days-off",
    component: _66b3197c,
    name: "user-days-off___hu"
  }, {
    path: "/hu/user/export",
    component: _1dd37abd,
    name: "user-export___hu"
  }, {
    path: "/hu/user/personal-information",
    component: _4c8e6ce8,
    name: "user-personal-information___hu"
  }, {
    path: "/en/user/:id?",
    component: _13870681,
    name: "user-id___en",
    children: [{
      path: "account-settings",
      component: _2445dec1,
      name: "user-id-account-settings___en"
    }, {
      path: "approvers",
      component: _26a396c0,
      name: "user-id-approvers___en"
    }, {
      path: "days-off",
      component: _1eced0f7,
      name: "user-id-days-off___en"
    }, {
      path: "export",
      component: _1e71c1b2,
      name: "user-id-export___en"
    }, {
      path: "personal-information",
      component: _429239c6,
      name: "user-id-personal-information___en"
    }]
  }, {
    path: "/hu/user/:id?",
    component: _13870681,
    name: "user-id___hu",
    children: [{
      path: "account-settings",
      component: _2445dec1,
      name: "user-id-account-settings___hu"
    }, {
      path: "approvers",
      component: _26a396c0,
      name: "user-id-approvers___hu"
    }, {
      path: "days-off",
      component: _1eced0f7,
      name: "user-id-days-off___hu"
    }, {
      path: "export",
      component: _1e71c1b2,
      name: "user-id-export___hu"
    }, {
      path: "personal-information",
      component: _429239c6,
      name: "user-id-personal-information___hu"
    }]
  }, {
    path: "/user/:id?",
    component: _13870681,
    name: "user-id___hu___default",
    children: [{
      path: "account-settings",
      component: _2445dec1,
      name: "user-id-account-settings___hu___default"
    }, {
      path: "approvers",
      component: _26a396c0,
      name: "user-id-approvers___hu___default"
    }, {
      path: "days-off",
      component: _1eced0f7,
      name: "user-id-days-off___hu___default"
    }, {
      path: "export",
      component: _1e71c1b2,
      name: "user-id-export___hu___default"
    }, {
      path: "personal-information",
      component: _429239c6,
      name: "user-id-personal-information___hu___default"
    }]
  }, {
    path: "/",
    component: _50d3add7,
    name: "index___hu___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
